.nav-footer {
	//position: absolute;
	/*background-image: -webkit-linear-gradient(270deg, $nav-background-shade, transparent);
	background-image: linear-gradient(270deg, $nav-background-shade, transparent); 
	background-color: $nav-background;*/
	
	@extend %nav-bg;

    //width: 100%;
    height: $footer-height;
    bottom: 0;
    display: flex;

    @include transition(0.3s,ease-in-out);

	.nav-footer-buttons {

		> li {
			> a {
				display: block;
				color: $nav-footer-link-color;
				height:$footer-height - 1;
				line-height: $footer-height - 1;
				margin-top: 1px;
				padding: 0 13px;
				overflow: visible;
				font-size: rem($fs-xl);
			}
		}
	}

	[data-class="nav-function-minify"] {
		display: none;
	}
}

.nav-function-fixed {

	.nav-footer {

		background: $nav-background;
		border:0;

		&:before {
			content:' ';
			height: 1px;
			position:inherit;
			width:inherit;
			background: rgba($nav-title-border-bottom-color, 0.2);
			background: -moz-linear-gradient(left, $nav-background 0%, lighten($nav-background, 15%) 50%, lighten($nav-background, 15%) 50%, $nav-background 100%);
			background: -webkit-linear-gradient(left, $nav-background 0%, lighten($nav-background, 15%) 50%, lighten($nav-background, 15%) 50%, $nav-background 100%);
			background: linear-gradient(to right, $nav-background 0%, lighten($nav-background, 15%) 50%, lighten($nav-background, 15%) 50%, $nav-background 100%);
			opacity: 0.5;
		}

	    &:after {
			opacity: 0.1;		    	
	    }

	}

}

@include media-breakpoint-up(lg) {

	.nav-function-minify {

		.nav-footer {

			background-color: darken($nav-background, 2%);

			[data-class="nav-function-minify"] {
				display: block;
				width:100%;
				height:$footer-height;
				line-height: $footer-height;
				font-size: rem($nav-font-link-size+5);
				vertical-align: middle;
				color: $nav-icon-color;
				text-align: center;
				text-decoration: none;
				position: relative;

				@include transition(0.3s,ease-in-out);

				> :first-child {
					margin-right: -4px;
				}

				> :only-child {
					margin:0;
				}
		

			}

			&:hover {
				background-color: lighten($nav-background, 3%);

				[data-class="nav-function-minify"] {
					color: $nav-icon-hover-color;
					margin-left:7px;
				}
			}

			.nav-footer-buttons {
				display: none;
			}
		}
	}
}
