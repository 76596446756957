.page-wrapper {
	//overflow: hidden; //disabled because the menu items was not showing 
	position: relative;
}

.page-inner {
	min-height: 100vh;
}

.page-wrapper, .page-inner {
    display: flex;
    align-items: stretch;
    flex: 1 1 auto;
    width: 100%;
}

.page-content-wrapper {
	background-color: $page-bg;
    display: flex;
    align-items: stretch;
    flex: 1 1 auto;
    padding: 0;

    flex-basis: 100%;
    flex-direction: column;
    width: 0;
    min-width: 0;
    max-width: 100%;

    min-height: 1px;        
}