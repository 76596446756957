.badge.badge-icon {
	position: absolute;
	display: inline-block;
	background-color: $nav-badge-bg-color;
	color: $nav-badge-color;	
	@include box-shadow(0 0 0 1px $header-bg);
	cursor: default;
	border: 1px solid transparent;
	font-size: rem(10px);
	min-width: 1rem;
	max-width: $header-badge-min-width + 0.4375rem;
	padding: 0 3px;
	border-radius: $header-badge-min-width;
	font-weight: 500;
	line-height: normal;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	/* when self is relative */
	vertical-align: middle;
}

/* parent position needs to be relative, and turn off waves function */
.btn-icon .badge {
	top: auto;
	&.pos-top {
		margin-top: -4px;
	}
	&.pos-bottom {
		margin-bottom: -4px;
	}
	&.pos-left {
		margin-left: -4px;
	}
	&.pos-right {
		margin-right: -4px;
	}
}