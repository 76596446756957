/*

	DOC: In Bootstrap there is a small snippet added by the team for IE10 in windows 8 the 
	following comments by the author states:

		IE10 in Windows (Phone) 8

		Support for responsive views via media queries is kind of borked in IE10, for
		Surface/desktop in split view and for Windows Phone 8. This particular fix
		must be accompanied by a snippet of JavaScript to sniff the user agent and
		apply some conditional CSS to *only* the Surface/desktop Windows 8. Look at
		our Getting Started page for more information on this bug.

		For more information, see the following:

		Issue: https://github.com/twbs/bootstrap/issues/10497
		Docs: http://getbootstrap.com/getting-started/#support-ie10-width
		Source: http://timkadlec.com/2013/01/windows-phone-8-and-device-width/
		Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/

		@-ms-viewport {
		width: device-width;
		}

	Solution: 
	http://msdn.microsoft.com/en-us/library/ie/hh771902(v=vs.85).aspx
	We add the following instead:

*/
body.desktop-detected {
    -ms-overflow-style: scrollbar;
}

/* Reset elms pos when js-waves-off is used */
/*.js-waves-off {
	position: relative;
    overflow: hidden;
    user-select: none;
    z-index: 0;
}*/

/*.btn {
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05), 
				0 1px 2px rgba(0, 0, 0, 0.1);

	&:hover,
	&:active {
		box-shadow: none;
	}
}*/

/* change the white to any color ;) */
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    -webkit-text-fill-color: inherit !important;
}
/* select background */
::selection {
  background: $color-fusion;
  color: $white;
}
::-moz-selection {
  background: $color-fusion;
  color: $white;
}
/* remove dotted line from focus */
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
/* IE input clear field "X" input remove */
::-ms-clear {
  width : 0;
  height: 0;
}
/* links */
a { text-decoration: none !important }
/* touch action */
a, area, button, [role="button"], input, label, select, summary, textarea {
    touch-action: manipulation;
}
a[target]:not(.btn){
	font-weight:500;
    text-decoration-skip-ink: auto;
    text-decoration: underline !important;
}
/* btn active */
/*.btn.active, 
.btn:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
}*/
/* dot bullet */
.dot {
	/*width: 4px;
	height: 4px;
	display: inline-block;
	line-height: 0;
	border-radius: 100%;*/
	font-size: 4px !important;
	margin-right: $p-2 !important;
	margin-left:  -8px !important;
}
/* forms */
/* fix alignment for custom controls */
/*.custom-control {
	display: flex;
    align-items: center;
}*/
select.custom-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}
select.custom-select::-ms-expand {
    display: none;
}
/* bootstrap modal remove padding */
/* you need to disable this if you do not plan on using _addon-custom-scrollbar.scss */
body:not(.mod-main-boxed):not(.mobile-view-activated).chrome.modal-open {
	padding-right: 8px !important;
}
body:not(.mobile-view-activated).mod-main-boxed.modal-open {
	padding-right: 0px !important;
}

/* hover adjustment for close buttons */
.close:not(:disabled):not(.disabled):hover, 
.close:not(:disabled):not(.disabled):focus {
    color: inherit;
}

/* add borders to button groups */
.btn-group .btn:not([class*="btn-outline-"]):not(.btn-icon):not(.btn-light) {
	border-right: 1px solid rgba($black, 0.1);
	border-left: 1px solid rgba($black, 0.1);
}

.input-group-prepend .btn:not([class*="btn-outline-"]):not(.btn-icon):not(:first-child) {
	border-left: 1px solid rgba($black, 0.1);
}
.input-group-append .btn:not([class*="btn-outline-"]):not(.btn-icon):not(:first-child) {
	border-left: 1px solid rgba($black, 0.1);
}

.btn-group-vertical .btn:not([class*="btn-outline-"]):not(:first-child),
.btn-group-vertical .btn-group {
	border-top: 1px solid rgba($black, 0.1);
}