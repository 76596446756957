div#cont {
    padding: 0 !important;
}

svg {
    &#canvas {
        border: none !important;
        border-radius: $border-radius;
        padding: 10px;

        g{
            circle {
                &.success-fill {
                    filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, .9));
                    fill: $color-success;
                }

                &.primary-fill {
                    filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, .9));
                    fill: $color-info;
                }
            }

            rect {
                filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, .9));
                rx: 7px;
                ry: 7px;
            }

            .warning-fill {
                fill: $color-info;
            }

            .text {
                font-weight: 700;

                &.primary-fill {
                    fill: $black;
                }
            }
        }
    }
}