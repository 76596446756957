:-webkit-full-screen  {
   	[data-action="app-fullscreen"] {
		color: $disabled;
	} 
}

:-moz-full-screen {
   	[data-action="app-fullscreen"] {
		color: $disabled;
	} 
}