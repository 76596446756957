$mod-bg-1: url("#{$baseURL}img/backgrounds/bg-1.png") !default;
$mod-bg-1-prev: url("#{$baseURL}img/backgrounds/prev-bg-1.png") !default;

$mod-bg-2: url("#{$baseURL}img/backgrounds/bg-2.png") !default;
$mod-bg-2-prev: url("#{$baseURL}img/backgrounds/prev-bg-2.png") !default;

$mod-bg-3: url("#{$baseURL}img/backgrounds/bg-3.png") !default;
$mod-bg-3-prev: url("#{$baseURL}img/backgrounds/prev-bg-3.png") !default;

$mod-bg-4: url("#{$baseURL}img/backgrounds/bg-4.png") !default;
$mod-bg-4-prev: url("#{$baseURL}img/backgrounds/prev-bg-4.png") !default;

@include media-breakpoint-up(xl) {

	.mod-main-boxed.mod-bg-1 [data-class="mod-bg-1"],
	.mod-main-boxed.mod-bg-2 [data-class="mod-bg-2"],
	.mod-main-boxed.mod-bg-3 [data-class="mod-bg-3"],
	.mod-main-boxed.mod-bg-4 [data-class="mod-bg-4"],
	.mod-main-boxed.mod-bg-none [data-class="mod-bg-none"] {
		&:before {
			content: " ";
			display: block;
			border-radius: 50%;
			background: inherit;
			background-image: none;
			border: 2px solid rgba(0,0,0,0.2);
			position: absolute;
			top: 15px;
			left: 15px;
			height: 20px;
			width: 20px;
		}
		&:after {
			content: " ";
			height: inherit;
			width: inherit;
			border: 5px solid rgba(0,0,0,0.1);
			position: absolute;
			left: 0;
			top: 0;
			border-radius: 50%;
		} 			
	}


	.mod-main-boxed {
		.settings-panel {
			.expanded {
				display: block;

				> ul {

					> li {
						display: inline-block;
						margin:0;
						padding:0;

						[data-action] {

							&[data-class="mod-bg-1"] {
								background-image: $mod-bg-1-prev;
							}
							&[data-class="mod-bg-2"] {
								background-image: $mod-bg-2-prev;
							}
							&[data-class="mod-bg-3"] {
								background-image: $mod-bg-3-prev;
							}
							&[data-class="mod-bg-4"] {
								background-image: $mod-bg-4-prev;
							}
						}

						&:last-child {
							[data-action="toggle"] {
								margin-right:0;
							}
						}
					}
				}
			}
		}
		&.mod-bg-1 {
			background-image: $mod-bg-1;
		}
		&.mod-bg-2 {
			background-image: $mod-bg-2;
		}
		&.mod-bg-3 {
			background-image: $mod-bg-3;
		}
		&.mod-bg-4 {
			background-image: $mod-bg-4;
		}
		&.mod-fixed-bg {
			background-attachment: fixed;
		}
	}
}