#tabs,
#gbox_dashboard_grid {
	&.ui-jqgrid {
		border: none;

		.ui-jqgrid {
			border: none;

			.ui-pg-button {
				color: rgba($black, 0.3);
				font-weight: 600;
				transition: 0.3s all ease;

				&:hover {
					background-color: transparent;
					color: rgba($black, 1);
				}
			}

			.table-bordered{
				border: none;

				th {
					border: none;
					font-weight: 900;
					color: rgba($black, 0.5);

					.ui-th-div {
						padding-left: 0 !important;
					}
				}

				td {
					border: none;
				}
			}
		}
	}
}

#gbox_dashboard_grid {
	&.ui-jqgrid {
		border: none;

		.ui-pg-button {
			color: rgba(black, 0.3);
			font-weight: 600;
			transition: 0.3s all ease;

			&:hover {
				background-color: transparent;
				color: rgba($black, 1);
			}
		}

		.table-bordered{
			border: none;

			th {
				border: none;
				font-weight: 900;
				color: rgba($black, 0.3);
			}

			td {
				border: none;
			}
		}
	}
}