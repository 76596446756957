.card-header {
	color: inherit;
	background-color: $frame-border-color;
	small {
		margin:0;
		opacity: 0.8;
		font-weight: 400;
		font-size: 85%;
	}
} 

/* remove extra margin in card child items */
.card,
.card-group {
	box-shadow: 0px 0px 13px 0px rgba(darken($primary-800, 10%), (8/100));

	> :last-child {
		margin-bottom: 0px;
	}
}

.accordion > .card {
	box-shadow: none;
}

.card-group > .card {
	box-shadow: none;
}

/* remove wierd line height issue */
.card-header-pills,
.card-header-tabs {
	font-size: 0;

	.nav-link {
		font-size: $font-size-base;
	}
}

/* card title */
.card-title {
	font-size: $h5-font-size;	
}

.card-header .card-title {
	display: inline-block;
	padding: 0;
	margin: 0 0.5rem 0 0;
}