//highlight on-demand
@-webkit-keyframes highlight {
    from { background: $warning-50; }
    to { background: transparent; }
}

@keyframes highlight {
    from { background: $warning-50; }
    to { background: transparent; }
}

.highlight {
    -webkit-animation: highlight 1.5s;
       -moz-animation: highlight 1.5s;
            animation: highlight 1.5s;
}