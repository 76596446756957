.page-content {
	color: $black;
	//align-items: stretch;
	background-color: $color-primary;
	flex: 1 1 auto;
	order: 3;

	display: flex;
	flex-direction: column;
	position: relative;

	//min-height: calc(100vh - #{$header-height + $footer-height}) //spoonfeeding IE

	.has-arrow{
		position: relative;
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			right: 7%;
			width: 0;
			height: 0;
			border: 20px solid transparent;
			border-top-color: $color-primary;
			border-bottom: 0;
			border-left: 0;
			margin-bottom: -20px;
		}
	}
}

.page-content-wrapper {
	.has-arrow{
		position: relative;
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 7%;
			width: 0;
			height: 0;
			border: 20px solid transparent;
			border-top-color: $color-primary;
			border-bottom: 0;
			border-left: 0;
			margin-bottom: -20px;
		}
	}
}

/*@include media-breakpoint-up($mobile-breakpoint) {

	.nav-function-top {
		.page-content {
			min-height: calc(100vh - #{$header-height-nav-top + $nav-top-height + $footer-height})
		}
	}

}

*/