.carousel-indicators li {
	border-radius: 50%;
}

.carousel-control-prev:hover {
	/*background: -moz-linear-gradient(left, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 45%);
	background: -webkit-linear-gradient(left, rgba(0,0,0,0.25) 0%,rgba(0,0,0,0) 45%); */
	background: linear-gradient(to right, rgba(0,0,0,0.25) 0%,rgba(0,0,0,0) 45%); 
}

.carousel-control-next:hover {
	/*background: -moz-linear-gradient(right, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 45%); 
	background: -webkit-linear-gradient(right, rgba(0,0,0,0.25) 0%,rgba(0,0,0,0) 45%);*/ 
	background: linear-gradient(to left, rgba(0,0,0,0.25) 0%,rgba(0,0,0,0) 45%); 
}