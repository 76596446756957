.loader {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	align-items: center;
	justify-content: center;
	background: rgba(255,255,255,0.5);
	color: inherit;
	z-index: 10;

	/*
	The use of translate3d pushes CSS animations into hardware acceleration.
	Even if you're looking to do a basic 2d translation, use translate3d for more power!
	If your animation is still flickering after switching to the transform above,
	you can use a few little-known CSS properties to try to fix the problem:
	*/
	/*-webkit-transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1000;	*/

}


/*:not(.enable-loader) .loader {
	> * {
		animation: pause;
	}
}*/

.enable-loader {
	&:before {
		content: '';
		background-color: rgba($white, 0.7);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.loader {
		display: flex;
	}
}


