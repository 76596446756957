.color-disp-demo {
	tr {

		&:first-child {
			td {
				height: 100px;
				box-shadow: inset 0 -5px 0 rgba(255,255,255,0.8);
			}
		}
		td {
			border:none;
			padding-top: 7px;
			padding-bottom: 7px;

			@extend %common-animation;

			&:hover {
				@include transform( scale(1.1) );
				font-weight: 500;
			}
		}	
	}
}

.cr-c {
	width: 21px;
	height: 21px;
	display: block;
	border-radius: 50%;
}

.icon-demo {
	list-style: none;
	padding:0;
	li {
		border:1px solid #7f8995;
		padding:10px;
		display: inline-block;
		font-size:20px;
		width:60px;
		height:60px;
		//line-height: 43px;
		overflow: hidden;
		margin: 0 6px 0 0;
		text-align: center;
		background:#fff;

		@extend %common-animation;

		&:hover {
			@include transform( scale(1.4) );
		}
	}
}

//display demo codes
//works with vendor/prism/prism.css
	code[class*="language-"],
	pre[class*="language-"] {
		display: none !important;
	}

.show-codes {
	code[class*="language-"],
	pre[class*="language-"] {
		display: block !important;
	}
}

.container-demo {
	max-width:100%;
	width:100%;
}

.bd-example {
    position: relative;
    padding: 1rem 0;
}

.bd-example-row-flex-cols .row {
    min-height: 10rem;
    background-color: rgba(255,0,0,.1);
}
.bd-example-row .row>.col, 
.bd-example-row .row>[class^=col-] {
    padding-top: .75rem;
    padding-bottom: .75rem;
    background-color: rgba(86,61,124,.15);
    border: 1px solid rgba(86,61,124,.2);
}

.bd-example-row .row+.row {
    margin-top: 1rem;
}

.bd-highlight {
  background-color: rgba(86,61,124,.2);
  border: 1px solid rgba(86,61,124,.2);
}

//demo

.demo {
	margin: 0;

	> * {
		margin: 0 .375rem 1rem 0 !important;
	}

	&.demo-no-mb {
		> * {
		margin-bottom: 0 !important;
		}
	}

}

.demo-v-spacing-sm,
.demo-v-spacing,
.demo-v-spacing-lg {
	> * + *  {
		margin-top: 0.875rem !important;
		margin-bottom: 0 !important;
	}

	> * {
		margin-bottom: 0 !important;
	}
}



.demo-v-spacing {
	> * + *  {
		margin-top: 1rem !important;
	}
}

.demo-v-spacing-lg {
	> * + *  {
		margin-top: 1.5rem !important;
	}
}

.demo-h-spacing > *:not(last-child):not(only-child) {
	margin-right: 1rem !important;
}

/*.demo-vh-spacing > *:not(last-child):not(only-child) {
	margin: 0 .375rem 1rem 0 !important;
}*/
