// primary
@include paint($primary-50, bg-primary-50);
@include paint($primary-100, bg-primary-100);
@include paint($primary-200, bg-primary-200);
@include paint($primary-300, bg-primary-300);
@include paint($primary-400, bg-primary-400);
@include paint($primary-500, bg-primary-500);
@include paint($primary-600, bg-primary-600);
@include paint($primary-700, bg-primary-700);
@include paint($primary-800, bg-primary-800);
@include paint($primary-900, bg-primary-900);

@include brush($primary-50, color-primary-50);
@include brush($primary-100, color-primary-100);
@include brush($primary-200, color-primary-200);
@include brush($primary-300, color-primary-300);
@include brush($primary-400, color-primary-400);
@include brush($primary-500, color-primary-500);
@include brush($primary-600, color-primary-600);
@include brush($primary-700, color-primary-700);
@include brush($primary-800, color-primary-800);
@include brush($primary-900, color-primary-900);

// success
@include paint($success-50, bg-success-50);
@include paint($success-100, bg-success-100);
@include paint($success-200, bg-success-200);
@include paint($success-300, bg-success-300);
@include paint($success-400, bg-success-400);
@include paint($success-500, bg-success-500);
@include paint($success-600, bg-success-600);
@include paint($success-700, bg-success-700);
@include paint($success-800, bg-success-800);
@include paint($success-900, bg-success-900);

@include brush($success-50, color-success-50);
@include brush($success-100, color-success-100);
@include brush($success-200, color-success-200);
@include brush($success-300, color-success-300);
@include brush($success-400, color-success-400);
@include brush($success-500, color-success-500);
@include brush($success-600, color-success-600);
@include brush($success-700, color-success-700);
@include brush($success-800, color-success-800);
@include brush($success-900, color-success-900);

// info
@include paint($info-50, bg-info-50);
@include paint($info-100, bg-info-100);
@include paint($info-200, bg-info-200);
@include paint($info-300, bg-info-300);
@include paint($info-400, bg-info-400);
@include paint($info-500, bg-info-500);
@include paint($info-600, bg-info-600);
@include paint($info-700, bg-info-700);
@include paint($info-800, bg-info-800);
@include paint($info-900, bg-info-900);

@include brush($info-50, color-info-50);
@include brush($info-100, color-info-100);
@include brush($info-200, color-info-200);
@include brush($info-300, color-info-300);
@include brush($info-400, color-info-400);
@include brush($info-500, color-info-500);
@include brush($info-600, color-info-600);
@include brush($info-700, color-info-700);
@include brush($info-800, color-info-800);
@include brush($info-900, color-info-900);

// warning
@include paint($warning-50, bg-warning-50);
@include paint($warning-100, bg-warning-100);
@include paint($warning-200, bg-warning-200);
@include paint($warning-300, bg-warning-300);
@include paint($warning-400, bg-warning-400);
@include paint($warning-500, bg-warning-500);
@include paint($warning-600, bg-warning-600);
@include paint($warning-700, bg-warning-700);
@include paint($warning-800, bg-warning-800);
@include paint($warning-900, bg-warning-900);

@include brush($warning-50, color-warning-50);
@include brush($warning-100, color-warning-100);
@include brush($warning-200, color-warning-200);
@include brush($warning-300, color-warning-300);
@include brush($warning-400, color-warning-400);
@include brush($warning-500, color-warning-500);
@include brush($warning-600, color-warning-600);
@include brush($warning-700, color-warning-700);
@include brush($warning-800, color-warning-800);
@include brush($warning-900, color-warning-900);

// danger
@include paint($danger-50, bg-danger-50);
@include paint($danger-100, bg-danger-100);
@include paint($danger-200, bg-danger-200);
@include paint($danger-300, bg-danger-300);
@include paint($danger-400, bg-danger-400);
@include paint($danger-500, bg-danger-500);
@include paint($danger-600, bg-danger-600);
@include paint($danger-700, bg-danger-700);
@include paint($danger-800, bg-danger-800);
@include paint($danger-900, bg-danger-900);

@include brush($danger-50, color-danger-50);
@include brush($danger-100, color-danger-100);
@include brush($danger-200, color-danger-200);
@include brush($danger-300, color-danger-300);
@include brush($danger-400, color-danger-400);
@include brush($danger-500, color-danger-500);
@include brush($danger-600, color-danger-600);
@include brush($danger-700, color-danger-700);
@include brush($danger-800, color-danger-800);
@include brush($danger-900, color-danger-900);

// fusion
@include paint($fusion-50, bg-fusion-50);
@include paint($fusion-100, bg-fusion-100);
@include paint($fusion-200, bg-fusion-200);
@include paint($fusion-300, bg-fusion-300);
@include paint($fusion-400, bg-fusion-400);
@include paint($fusion-500, bg-fusion-500);
@include paint($fusion-600, bg-fusion-600);
@include paint($fusion-700, bg-fusion-700);
@include paint($fusion-800, bg-fusion-800);
@include paint($fusion-900, bg-fusion-900);

@include brush($fusion-50, color-fusion-50);
@include brush($fusion-100, color-fusion-100);
@include brush($fusion-200, color-fusion-200);
@include brush($fusion-300, color-fusion-300);
@include brush($fusion-400, color-fusion-400);
@include brush($fusion-500, color-fusion-500);
@include brush($fusion-600, color-fusion-600);
@include brush($fusion-700, color-fusion-700);
@include brush($fusion-800, color-fusion-800);
@include brush($fusion-900, color-fusion-900);

//white
@include brush($white, color-white);
@include brush(lighten($black, 13.5%), color-black);


@include paint-gradient($primary-900, bg-primary-gradient);
@include paint-gradient($danger-900, bg-danger-gradient);
@include paint-gradient($info-900, bg-info-gradient);
@include paint-gradient($warning-900, bg-warning-gradient);
@include paint-gradient($success-900, bg-success-gradient);
@include paint-gradient($fusion-900, bg-fusion-gradient);