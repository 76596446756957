@import url($font-import);

body {
	font-family: $page-font;
	font-size: rem($fs-base);
	letter-spacing: 0.1px;
}

.page-content {
	color: $base-text-color;
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1.3;
	font-weight: 400;
	font-family: $heading-font-family;
	color:$black;
}

strong {
	font-weight: 500;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small {
	font-weight: 300;
	display: block;
	font-size: rem($fs-lg);
	line-height: 1.5;
	//letter-spacing: -0.2px;
	margin:2px 0 ($grid-gutter-width-base / 2);
}

h2 small,
h3 small,
.h2 small,
.h3 small, {
	font-size: rem($fs-lg);
}

h4 small,
.h4 small {
	font-size: rem($fs-md);
}

h5 small,
h6 small,
.h5 small,
.h6 small {
	font-size: rem($fs-base);
}

/* contrast text */
.text-contrast {
	color: lighten($black, 20%);
}

/* text-gradient */
.text-gradient {
	background: -webkit-linear-gradient(180deg, $primary-700 25%, $primary-800 50%, $info-700 75%, $info-900 100%);
	background: linear-gradient(180deg, $primary-700 25%, $primary-800 50%, $info-700 75%, $info-900 100%);
	color: $primary-500;
	background-clip: text;
	text-fill-color: transparent;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	text-shadow: none;
}

/* looking for font size? Check _helpers.scss */